@import 'assets/styles/Colors.scss';

.IconLink {
    display: flex;
    align-items: center;
    color: white;

    &:hover {
        cursor: pointer;
        color: transparentize($color: white, $amount: 0.3);
    }

    &.Big {
        .Icon {
            font-size: 1.5rem;
            margin: 0 1rem;
        }
    }

    &.Normal {
        .Icon {
            margin: 0 0.4rem;
        }
    }


    .Title {
        font-size: 1.1em;
    }
}