@import 'assets/styles/Colors.scss';

@keyframes Spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .LoadingSpinner {
    width: 50px;
    height: 50px;
    border: 10px solid #6e6e6e;
    border-top: 10px solid #FFFFFF;
    border-radius: 50%;
    animation: Spinner 1.5s linear infinite;
  }
  .SpinnerContainer {
    display: grid;
    position: absolute;
    width: 60%;
    //top: 0px;
    //left: 0px;
    justify-content: center;
    align-items: center;
    height: 90%;
  }