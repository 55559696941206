@import 'assets/styles/Colors.scss';

.ChangeBtn {
    background: white;
    display: flex;
    padding: 0.8rem 1rem;
    border-radius: 1rem;
    border: none;
    margin: 1rem 0 0;
    width: 10rem;
    height: auto;

    &.harmonyca {
        background: none;
        width: 13rem;
    }

    .Logo {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

}