@import 'assets/styles/Colors.scss';

.Body {
    background: linear-gradient(90deg, #404044 16.22%, #5C5E63 56.23%, #3F3F44 97.38%), linear-gradient(90deg, #45454B 50%, #5C5E63 60.3%, #3F3F44 73.65%);
    min-height: calc(100% - 4.5rem);
    padding-bottom: 10rem;

    * {
        font-family: 'bebas-neue-pro';
    }

    .DetailsWrapper {
        margin: auto;
        // max-width: 99.857rem;
        padding-top: 0.7rem;

        .Title {
            color: white;
            font-family: 'bebas-neue-pro';
            font-size: 3.5rem;
            font-weight: bold;
            text-align: center;
            text-decoration: overline underline;
            // border-bottom: 4px solid white;
            // border-top: 4px solid white;
            // width: 330px;
            // margin: auto;
        }

        .Title sup{
            font-size: 2rem;
        }

        .MainGrid {
            display: grid;
            gap: 2rem;
            grid-template-columns: repeat(1,minmax(0,1fr));
            max-width: 72rem;
            margin: 4rem auto;

            .ChildGrid {
                display: flex;
                flex-direction: column;
                text-align: center;
                padding: 2rem;
                border-radius: 1rem;
                background: gray;
                color: white;

                h2 {
                    font-size: 2.5rem;
                    margin: 0;
                    font-style: italic;
                    text-transform: uppercase;
                    
                    sup {
                        margin-left: 0.25rem;
                    }
                }

                sup {
                    font-size: 1.25rem;
                }

                .SubTitle {
                    margin-top: 0.5rem;
                    font-size: 1.5rem;
                    text-transform: uppercase;
                }

                .Text {
                    font-size: 1.35rem;
                    margin-top: 1rem;
                }
            }

            @media (min-width: 1280px) { 
                & {
                    grid-template-columns: repeat(3,minmax(0,1fr));
                }
            } 
        }

        /* .Details {
            background: rgba(255, 255, 255, 0.1);
            border-radius: 10px;
            box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.1);
            color: white;
            display: flex;
            padding: 1rem 1rem;

            sup {
                font-size: 0.6rem;
            }

            .FirstColumn{
                flex: 5 0 0;
                padding: 1rem;
            }
            .SecondColumn {
                flex: 2 0 0;
                padding: 1rem;
            }
            .ThirdColumn {
                flex:5 0 0;
                padding: 1rem;
            }
            .SecondColumn {
                h3 {
                     
                        margin-top: -20px;
                        margin-bottom: 15px;
                   
                }
                .subtitle {
                    color: #6c1669;
                    font-weight: bold;
                }
                .highlighted {
                    color: #6c1669;
                  
                }
                .grafico {
                    
                    width: 548px;
                    height: 375px;
                    padding-top: 20px;
                   
                }
            }

            .FirstColumn {
                .VistabelPills {
                    position: relative;
                    top: 4rem;
                }
                div:first-child {
                    h3 {
                        margin-top: -20px;
                        margin-bottom: 15px;
                    }
                }
                .subtitle {
                    color: #6c1669;
                    font-weight: bold;
                 
                }
                .highlighted {
                    color: #6c1669;
                  
                }
                .grafico {
                    
                    width: 320px;
                    height: 248px;
                    padding-top: 10px;
                }
            }
            .ThirdColumn {
              
                h3{
                     
                        margin-top: -20px;
                        margin-bottom: 15px;
                   
                }
                .subtitle {
                    color: #6c1669;
                    font-weight: bold;
                }
                .highlighted {
                    color: #6c1669;
                  
                }
               
            }
        } */
    }

    .BackBtn {
        display: flex;
        justify-content: center;
        margin-top: 1.5rem;
    }
}

.ReferenceIcon {
    position: fixed;
    left: 2rem;
    bottom: 9rem;
    width: 2.5rem;

    &:hover {
        cursor: pointer;
    }
}

.ReferencesModal {
    max-width: 42rem;

    .References {
        background: transparentize(black, 0.2);
        border-radius: 1rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin: 2rem auto;

        .Reference {
            font-size: 1rem;
            color: white;
            text-align: left;
        }
    }
}