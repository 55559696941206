// Main used colors
$button-blue: #006ce5;
$light-blue: #548AD8;
$header-pink: #F0B17D;
$clearest-orange: #EFCBB3;
$light-orange: #C9997B;
$darker-orange: #B66D3F;
$infocard-bg-lightviolet: #4A2554;
$infocard-border-lightviolet: #A14DB7;
$magenta: #7D2A8F;
$dark-magenta: #5E256B;
$light-violet: #A351B8;
$mid-violet: #8A4BD3;
$violet: #582C65;
$grape: #3D1A39;
$dark-violet: #361D3D;
$infocard-bg-violet: #361D3D;
$light-aubergine: #6A23BC;
$aubergine: #2A1328;
$table-bg-gray: #303030;
$background-gray:  linear-gradient(90deg, #404044 16.22%, #5C5E63 56.23%, #3F3F44 97.38%), linear-gradient(90deg, #45454B 50%, #5C5E63 60.3%, #3F3F44 73.65%);
$orange-gradient: linear-gradient(180deg, $light-orange 0%, $darker-orange 100%);
$violet-gradient: linear-gradient(180deg, $violet 0%, $dark-violet 100%);
$orange-to-violet: linear-gradient(98.96deg, $header-pink 16.92%, $mid-violet 85.29%);
$magenta-to-darkmagenta: linear-gradient(180deg, $magenta 30.73%, $dark-magenta 100%);
$darkmagenta-gradient: linear-gradient(180deg, $mid-violet 30.73%, $light-aubergine 100%);
$aubergine-gradient: linear-gradient(180deg, #4F2254 0%, #331238 61.98%);
$lollipop-gradient: linear-gradient(180deg, #8A658C 30.73%, #632165 100%);
$juvederm-connection: #813582;
$juvederm-relation: #EDB17F;
$juvederm-collaboration: #9F82B5;
$bright-orange: #F1B17E;
$login-pink: #DB9B8F;
$dark-gray: #404040;