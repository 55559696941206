@import 'assets/styles/Colors.scss';

.Dimmer {
    backdrop-filter: blur(0.5rem);
    background: transparentize(black, 0.9);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0rem;
    z-index: 200;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .Modal {
        position: relative;
        max-width: 100vw;
        margin: auto;
        display: inline-block;

        .CloseButton {
            color: white;
            font-size: 2rem;
            position: absolute;
            top: 0rem;
            right: 0rem;
            z-index: 300;

            &:hover {
                color: lightgray;
                cursor: pointer;
            }
        }
    }
}