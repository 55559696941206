@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Wrapper {
    color: white;
    display: flex;
    height: 100%;
    width: 100%;

    .SideMenu {
        @include media('<=mobile') {
            height: 100%;
            position: absolute;
            z-index: 3;

            &:not(.VisibleMobile) {
                display: none;
            }
        }

        @include media('<=mobile') {
            &.VisibleMobile {
                + .Content {
                    &:before {
                        content: '';
                        background: transparentize(black, 0.5);
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .Content {
        background: $grape;
        height: 100%;
        width: 100%;
    }

    .Columns {
        display: flex;
        text-align: center;
        height: calc(100% - 10.063rem);

        @include media('<=tablet') {
            height: calc(100% - 7rem);
        }

        @include media('<=mobile') {
            height: calc(100% - 4.5rem);
        }

        .FirstColumn {
            width: 70%;

            @include media('<=mobile') {
                width: 100%;
            }
        }

        .SecondColumn {
            padding: 1rem 4rem 2rem 0;
            width: 30%;
            overflow: auto;

            @include media('<=mobile') {
                display: none;
            }

            .Title {
                display: flex;
                align-items: center;
                justify-content: center;
                
                & > p {
                    margin-right: 1rem;
                }
            }

            .ThirdsInfo {
                margin: 1rem 0 1.7rem;
                padding: 0.6rem 1.1rem 1.8rem;
                position: relative;

                h1 {
                    margin: 0 0 0.25rem;
                }

                h4 {
                    margin: 0;
                    font-weight: normal;
                }

                .Divider {
                    border-bottom: 1px solid $infocard-border-lightviolet;
                    margin: 0.5rem 0;
                }

                .FloatingButton {
                    bottom: -1rem;
                    left: 50%;
                    min-width: 10.625rem;
                    position: absolute;
                    transform: translate(-50%, 0);
                }
            }
        }

        .AppleSwitch {
            position: relative;
            appearance: none;
            outline: none;
            width: 2.6rem;
            height: 1.3rem;
            background-color: #ffffff;
            border: 1px solid #D9DADC;
            border-radius: 2.6rem;
            box-shadow: inset -1.25rem 0 0 0 #ffffff;
            transition-duration: 200ms;

            &:hover {
                cursor: pointer;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0.063rem;
                left: 0.063rem;
                width: 1.2rem;
                height: 1.2rem;
                background-color: transparent;
                border-radius: 50%;
                box-shadow: 0.125rem 0.25rem 0.375rem rgba(0,0,0,0.2);
            }

            &:checked {
                border-color: #4ED164;
                box-shadow: inset 1.25rem 0 0 0 #4ED164;

                &:after {
                    left: 1.25rem;
                    box-shadow: -0.125rem 0.25rem 0.188rem rgba(0,0,0,0.05);
                }
            }
        }

        .ChangeTo {
            text-align: center;
            font-family: 'freight-sans';
            font-weight: bold;
            margin: 2rem auto 0;

            .ChangeBtn {
                margin: 1rem auto 0;
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;

                & > img {
                    margin: auto;
                    width: 80%;
                }
            }
        }
    }
}

.Footer {
    display: block;
    // @include media('<=mobile') {
    //     display: none;
    // }
}

.ReferencesModal {
    max-width: 60%;

    & > div {
        background: transparentize(black, 0.2);
        border-radius: 1.875rem;
        color: white;
        display: inline-block;
        font-size: 1.2rem;
        padding: 2rem;

        p {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}