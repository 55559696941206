@import 'assets/styles/Colors.scss';

.ComparativeTable {
    background: $aubergine;
    border: 1px solid $light-orange;
    border-radius: 1.875rem;
    // display: inline-block;
    font-size: 1rem;
    padding: 1.2rem;
    overflow: auto;
    margin: auto;

    & > table {
        color: white;
        display: inline-block;
        text-align: center;
        border-collapse: collapse;

        .InfoIcon {
            text-align: left;

            img {
                height: 1.5rem;
                width: auto;
            }

            img:hover {
                cursor: pointer;
            }
        }

        & > thead {
            & th {
                max-width: 10.5rem;
                padding: 0 1.5rem;
                text-align: center;
            }

            & p {
                font-size: 1rem;
                font-weight: normal;
                margin-top: 1rem;
            }
            
            & sup {
                font-size: 0.8rem;
            }
        }

        tr:nth-child(1) {
            td:not(:first-child) {
                & > div {
                    display: inline-block;
                    position: relative;
    
                    & > span {
                        font-size: 0.85rem;
                        position: absolute;
                        top: -0.4rem;
                        right: -0.7rem;
                    }
                }
            }
        }

        tr:nth-child(4) {
            p:last-child {
                font-size: 0.85rem;
            }
        }
    
        .TitleColumn {
            text-align: start;
            font-size: 1.5rem;
            font-weight: normal;
            padding-right: 1.5rem;

            & sup {
                font-size: 0.6rem;
            }

            & > p{
                padding-left: 3.2rem;
                font-size: 0.8rem;                
            }
        }

        .Hourglass {
            width: 1.75rem;
            height: 2.5rem;
        }

        .Drops {
            margin-top: 0.3rem;
            width: auto;
            height: 1.3rem;
        }

        .Circles{
            width: auto;
            height: 0.85rem;
        }

        .Spring {
            margin-top: 0.3rem;
            width: auto;
            height: 2rem;
        }

        .Arrow {
            width: auto;
            height: 3.5rem;
        }
    
        .DarkBlueFont,
        .DarkVioletFont,
        .BlueFont,
        .VioletFont,
        .DarkGreenFont {
            font-size: 2.5rem;
            font-weight: normal;
            justify-content: center;
        }

        .FirstColumn,
        .SecondColumn,
        .ThirdColumn,
        .FourthColumn,
        .FifthColumn {

            &:not(th) {
                border-left: 2px solid $light-orange;
                font-size: 1.3rem;
                padding: 1rem 0;

                & sup {
                    font-size: 0.8rem;
                }
            }

            &.Hidden {
                display: none;
            }
        }
    
        .DarkBlueFont,
        .FifthColumn{
            color: #85A8C1;
        }
    
        .DarkVioletFont,
        .ThirdColumn {
            color: #8C83B9;
        }
    
        .VioletFont,
        .SecondColumn {
            color: #B479AD;
        }
    
        .DarkGreenFont,
        .FourthColumn {
            color: #686B6B;
        }
    
        .BlueFont,
        .FirstColumn {
            color: #6AC1DB;
        }
    }
}