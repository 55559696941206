@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansBold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansBook.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansBookItalic.otf') format('opentype');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansLight.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansLightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansMedium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'bebas-neue-pro';
    src: local('bebas-neue-pro'),
        url('assets/fonts/Bebas Neue Pro Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'bebas-neue-pro';
    src: local('bebas-neue-pro'),
        url('assets/fonts/Bebas Neue Pro Book.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: 'bebas-neue-pro';
    src: local('bebas-neue-pro'),
        url('assets/fonts/Bebas Neue Pro Light.otf') format('opentype');
    font-weight: 350;
}

@font-face {
    font-family: 'minion-pro';
    src: local('minion-pro'),
        url('assets/fonts/MinionPro-Regular.otf') format('opentype');
}

@font-face {
    font-family: 'newyork';
    src: local('newyork'),
        url('assets/fonts/NewYork.otf') format('opentype');
}

@font-face {
    font-family: 'hydrophilia';
    src: local('hydrophilia'),
        url('assets/fonts/Hydrophilia Iced.ttf') format('truetype');
}

@font-face {
    font-family: 'Beatrice';
    src: local('beatrice'),
        url('assets/fonts/Beatrice Light.ttf') format('truetype');
}

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansBold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'freight-sans';
    src: local('freight-sans'),
        url('assets/fonts/FreightSansBook.otf') format('opentype');
    font-weight: 400;
}

// nuevas
@font-face {
    font-family: 'Founders Grotesk';
    src: local('Founders Grotesk'),
        url('assets/fonts/FoundersGrotesk-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: local('Founders Grotesk'),
        url('assets/fonts/FoundersGrotesk-BoldItalic.otf') format('opentype');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: local('Founders Grotesk'),
        url('assets/fonts/FoundersGrotesk-Light.otf') format('opentype');
    font-weight: 300;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: local('Founders Grotesk'),
        url('assets/fonts/FoundersGrotesk-LightItalic.otf') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: local('Founders Grotesk'),
        url('assets/fonts/FoundersGrotesk-Regular.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: 'Founders Grotesk';
    src: local('Founders Grotesk'),
        url('assets/fonts/FoundersGrotesk-RegularItalic.otf') format('opentype');
    font-weight: 500;
    font-style: italic;
}

html {
    font-size: 16px;

    @include media('<=smallDesktop') {
        font-size: 14px;
    }
}

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    * {
        font-family: 'freight-sans', Arial, Helvetica, sans-serif;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
    margin: 0;
}
