@import 'assets/styles/Colors.scss';

.Button {
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 6.25rem;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);
    border: none;
    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }

    &.Magenta {
        background: $magenta-to-darkmagenta;
    }

    &.DarkMagenta {
        background: $darkmagenta-gradient;
    }

    &.Aubergine {
        background: $aubergine-gradient;
    }

    &.Lollipop {
        background: $lollipop-gradient;
    }
}