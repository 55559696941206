@import 'assets/styles/Colors.scss';

.JuvedermIconItem {
    display: flex;
    align-items: center;
    margin: 1px;

    &.Normal {
        & > img {
            margin-right: 1rem;
        }
    }

    &.Small {
        & > img {
            width: 1.4rem;
            margin-right: 2.4rem;
        }
    }    
}