@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Header {
    border-bottom: 1px solid $bright-orange;
    box-shadow: 0 0.25rem 0.938rem rgba(0, 0, 0, 0.25);
    padding-top: 0.4rem;
    padding-left: 8rem;
    position: relative;
}

main.Content {
    section {
        &.section1 {
            align-items: center;
            background: $header-pink;
            display: flex;
            justify-content: center;
            padding-top: 2.5rem;

            & > h1 > span {
                color: white;
                display: flex;
                flex-direction: column;
                font-family: 'newyork';
                font-size: 4.5rem;
                font-weight: 400;
                margin: 0;
                text-transform: uppercase;
            }
        }

        &.section2 {
            background: $grape;
            padding: 7rem 0 1rem 0;
            text-align: center;

            .Rectangle {
                display: flex;
                justify-content: center;
                flex-direction: column;
                position: relative;
                border: 1px solid white;
                border-radius: 10px;
                padding: 2rem 1.5rem 1.5rem 1.5rem;
                max-width: 38rem;
                margin: auto;
                gap: 1.5rem;

                h1 {
                    color: white;
                    font-size: 1.5rem;
                    position: absolute;
                    top: -0.75rem;
                    left: 50%;
                    margin: 0;
                    background: $grape;
                    font-family: 'newyork';
                    transform: translateX(-50%);
                    width: max-content;
                    font-weight: normal;
                    text-transform: lowercase;
                }

                img {
                    width: 100%;
                    margin: auto;
                }

                .Text {
                    color: $bright-orange;
                    font-size: 5rem;
                    font-family: 'newyork';
                    text-align: center;
                }

                @media (min-width: 1280px) { 
                    & {
                        padding: 4rem 3rem 3rem 3rem;
                    }

                    h1 {
                        font-size: 3rem;
                        top: -1.5rem;
                    }

                    img {
                        width: 90%;
                    }

                    .Text {
                        font-size: 7rem;
                    }
                } 
            }

            .DiscoverText {
                text-transform: uppercase;
                color: white;
                font-size: 1.5rem;
                margin-top: 2rem;

                span {
                    color: $bright-orange;
                    font-weight: bold;
                }
            }
        }

        &.section3 {
            background: $grape;
            padding: 1rem 0 1rem 0;
            text-align: center;

            h1 {
                color: $bright-orange;
                font-size: 1.5rem;
                background: $grape;
                font-weight: normal;
                margin-top: 6.875rem;

                span {
                    color: white;
                }
            }

            .MainGrid {
                display: grid;
                gap: 4rem;
                grid-template-columns: repeat(1,minmax(0,1fr));
                max-width: 58rem;
                margin: auto;
                align-items: start;

                .ChildGrid {
                    display: grid;
                    gap: 1rem;
                    grid-template-columns: repeat(1,minmax(0,1fr));

                    .ChildHeader {
                        position: relative;
                        display: flex;
                        align-items: center;
                        width: 100%;

                        h2 {
                            width: 100%;
                            padding: 1rem 0.5rem;
                            background: $bright-orange;
                            color: $grape;
                            font-size: 2rem;
                            border-radius: 10px;
                            margin: 0;
                        }

                        span {
                            display: none;
                            position: absolute;
                            color: #F1B17E;
                            right: -3rem;
                            top: 0.5rem;
                            font-size: 5rem;
                            line-height: 2.5rem;
                        }
                    }

                    img {
                        margin: auto;
                        width: 5rem;
                    }

                    .Text {
                        color: white;
                        font-size: 1.5rem;
                        text-align: left;
                    }
                }

                sup {
                    font-size: 1.25rem;
                }
            }

            @media (min-width: 768px) { 
                h1 {
                    font-size: 3rem;
                }

                .MainGrid {
                    grid-template-columns: repeat(3,minmax(0,1fr));

                    .ChildGrid {
                        .ChildHeader {
                            span {
                                display: block;
                            }
                        }
                    }
                }
            } 
        }

        &.section4 {
            background: $grape;
            border-bottom: 1px solid $bright-orange;
            padding: 1rem 0 1rem 0;

            .PropertiesRow,
            .VideoCardsRow {
                display: flex;
                justify-content: center;
            }

            .PropertiesRow {
                .Property {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    padding: 4.5rem 5.125rem 0;

                    .PropertyTitle {
                        font-size: 1.5rem;
                        font-weight: 500;
                        margin: 1.313rem 0 1rem;
                        padding: 0.3rem 1.938rem 0.1rem;
                        text-transform: uppercase;
                    }

                    & > span {
                        color: white;
                        font-size: 1.4rem;
                        font-weight: 500;
                        text-align: center;
                        text-transform: uppercase;

                        &:first-of-type {
                            margin-bottom: 0.1rem;
                        }
                    }

                    &.Connection {
                        .PropertyTitle {
                            background: $juvederm-connection;
                            color: white;
                        }

                        & > span {
                            &:first-of-type {
                                color: #9476A7;
                            }
                        }
                    }

                    &.Relation {
                        .PropertyTitle {
                            background: $juvederm-relation;
                            color: #4A1F47;
                        }

                        & > span {
                            &:first-of-type {
                                color: $juvederm-relation;
                            }
                        }
                    }

                    &.Collaboration {
                        .PropertyTitle {
                            background: $juvederm-collaboration;
                            color: white;
                        }

                        & > span {
                            &:first-of-type {
                                color: $juvederm-collaboration;

                                & > sup {
                                    font-size: 0.8rem;
                                }
                            }
                        }
                    }
                }
            }

            .VideoCardsRow {
                padding: 3.125rem 0;

                &:last-of-type {
                    padding-top: 0;
                }

                .VideoCard {
                    align-items: center;
                    background: #2D1129;
                    border: 1px solid $bright-orange;
                    border-radius: 2rem;
                    box-shadow: 0 0.25rem 0.938rem rgba(0, 0, 0, 0.25);
                    display: flex;
                    flex-direction: column;
                    height: 13rem;
                    justify-content: space-between;
                    margin: 0 1.66565rem;
                    padding: 1.75rem;
                    width: 14.7rem;

                    & > img {
                        width: 4.5rem;
                    }

                    .CardTitle {
                        color: white;
                        font-size: 2rem;
                        font-weight: 300;
                        margin: 0.5rem 0 0;
                        text-align: center;
                    }

                    .VideoLink {
                        display: flex;
                        align-items: center;

                        & > img {
                            margin-right: 0.8rem;
                        }

                        & > a {
                            color: white;
                            font-size: 1.1rem;
                            font-weight: 500;
                            text-decoration: underline;
                        }
                    }
                }
            }

            & > h2 {
                color: white;
                font-family: 'newyork';
                font-size: 2rem;
                font-weight: 400;
                margin-top: 6.875rem;
                text-align: center;
                text-transform: uppercase;
            }

            & > .SmallSeparator {
                border-bottom: 2px solid #DA9E76;
                margin: auto;
                width: 6.375rem;
            }
        }

        /* &.section5 {
            background: $aubergine;
            border-bottom: 1px solid $bright-orange;
            padding: 7.625rem 0 2rem;

            & > h1 {
                color: white;
                font-family: 'newyork';
                font-size: 2.2rem;
                font-weight: 400;
                line-height: 1.35;
                text-align: center;
                text-transform: uppercase;
            }

            & > .JuvedermSeparator {
                align-items: center;
                display: flex;
                justify-content: center;
                margin: 3rem 0 2rem;

                & > div {
                    background: white;
                    padding: 1px 0 0;
                    width: 23rem;
                }

                & > img {
                    width: 2.4rem;
                    margin: 0 2.5rem;
                }
            }

            & > p {
                color: white;
                font-size: 1.2rem;
                font-weight: 400;
                text-align: center;

                sup {
                    font-size: 0.8rem;
                }
            }

            .Benefits {
                padding: 4rem 0;

                .BenefitsRow {
                    display: flex;
                    justify-content: center;

                    .BenefitCard {
                        border-radius: 2.5rem;
                        box-shadow: 0.625rem 0.625rem 2.5rem rgba(68, 68, 68, 0.2);
                        height: 20rem;
                        margin: 2rem;
                        width: 23rem;

                        &.Even {
                            background: #833189;
                        }

                        &.Odd {
                            background: #53284F;
                        }

                        .BenefitHeader {
                            align-items: flex-end;
                            display: flex;
                            padding: 3.6rem 1.5rem 1.5rem;

                            .BenefitIndex {
                                align-items: flex-end;
                                color: #CE9FDA;
                                display: flex;
                                font-family: 'newyork';
                                font-size: 4.4rem;
                                font-weight: 400;
                                line-height: 3.3rem;
                                margin-right: 1rem;
                            }

                            .BenefitTitle {
                                color: white;
                                font-size: 1.55rem;
                                font-weight: 700;
                                line-height: 1.2;
                                margin: 0;
                                text-transform: uppercase;

                                &:first-letter {
                                    font-size: 2rem;
                                }
                            }
                        }

                        .BenefitDescription {
                            color: white;
                            font-size: 1.15rem;
                            font-weight: 400;
                            line-height: 1.4;
                            padding: 0 1.5rem;

                            sup {
                                font-size: 0.7rem;
                            }
                        }
                    }
                }
            }

            .BackBtn {
                display: flex;
                justify-content: center;
                margin-top: 1.5rem;
            }

            .ReferenceIcon {
                margin-left: 25rem;
                width: 2.5rem;

                &:hover {
                    cursor: pointer;
                }

                @include media('<=smallDesktop') {
                    margin-left: 15rem;
                }
            }
        } */

        &.finalSection {
            background: $aubergine;
            border-bottom: 1px solid $bright-orange;
            padding: 2rem 0 2rem;
    
            .BackBtn {
                display: flex;
                justify-content: center;
            }
        }
    }

    .ReferenceIcon {
        position: fixed;
        left: 2rem;
        bottom: 2rem;
        width: 2.5rem;

        &:hover {
            cursor: pointer;
        }
    }

    footer {
        .FooterRow {
            align-items: center;
            display: flex;
            justify-content: space-between;

            &:first-child {
                background: $grape;
                padding: 2.5rem 3rem;

                .PrivacyAndTerms {
                    display: flex;
                    
                    .Terms {
                        color: white;
                        text-decoration: underline;
                
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            &:last-child {
                background: #1C1C1C;
                padding: 1.5rem 3rem;

                & > img {
                    height: 1.5rem;
                }
            }

            & > img {
                height: 2.5rem;
            }

            & > a {
                align-items: center;
                display: flex;

                & > span {
                    color: white;
                    font-weight: 500;
                    margin-right: 0.7rem;
                    text-decoration: underline;
                }
            }

            & p {
                color: white;
                font-weight: 400;
            }
        }
    }
}

.ReferencesModal {
    max-width: 42rem;

    .References {
        background: transparentize(black, 0.2);
        border-radius: 1rem;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        margin: 2rem auto;

        .Reference {
            font-size: 1rem;
            color: white;
            text-align: left;
        }
    }
}