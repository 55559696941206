@import 'assets/styles/Colors.scss';

.TitleFaceSwitch {
    display: flex;
    align-items: center;
    justify-content: center;
    
    strong {
        margin-right: 1rem;
    }
}

.AppleFaceSwitch {
    position: relative;
    appearance: none;
    outline: none;
    width: 2.6rem;
    height: 1.3rem;
    background-color: #ffffff;
    border: 1px solid #D9DADC;
    border-radius: 2.6rem;
    box-shadow: inset -1.25rem 0 0 0 #ffffff;
    transition-duration: 200ms;

    &:hover {
        cursor: pointer;
    }

    &:after {
        content: "";
        position: absolute;
        top: 0.063rem;
        left: 0.063rem;
        width: 1.2rem;
        height: 1.2rem;
        background-color: transparent;
        border-radius: 50%;
        box-shadow: 0.125rem 0.25rem 0.375rem rgba(0,0,0,0.2);
    }

    &:checked {
        border-color: #4ED164;
        box-shadow: inset 1.25rem 0 0 0 #4ED164;

        &:after {
            left: 1.25rem;
            box-shadow: -0.125rem 0.25rem 0.188rem rgba(0,0,0,0.05);
        }
    }
}