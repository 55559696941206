@import 'assets/styles/Colors.scss';

.InfoCard {
    border-radius: 1.875rem;
    color: white;
    padding: 2.5rem;
    position: relative;
    text-align: left;

    h1 {
        font-size: 1.2rem;
    }

    sup {
        font-size: 0.6rem;
    }

    .Pink {
        color: #B479AD;
    }

    .Violet {
        color: #8C83B9;
    }

    .Green {
        color: #686B6B;
    }

    .Blue {
        color: #6AC1DB;
    }

    .DarkBlue {
        color: #5787A9;
    }

    &.LightViolet {
        background: $infocard-bg-lightviolet;
        border: 1px solid $infocard-border-lightviolet;
    }

    &.DarkViolet{
        background: $infocard-bg-violet;
        border: 1px solid $light-violet;
    }

    .FloatingButton {
        bottom: -1rem;
        left: 50%;
        min-width: 10.625rem;
        position: absolute;
        transform: translate(-50%, 0);
    }
}

.DividerInfoCard {
    border-bottom: 1px solid $infocard-border-lightviolet;
    margin: 0.4rem 0;
}

