@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.InputField {
    border-bottom: 2px solid white;
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem 0.4rem;

    &:hover {
        cursor: text;
    }

    &.Focused {
        .LabelText {
            transform: translateY(0);
            -webkit-transform: translateY(0);
        }
    }

    .LabelText {
        color: white;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 0.07rem;
        transform: translateY(1.7rem);
        -webkit-transform: translateY(1.7rem);
        text-shadow: 0px 0px 2px $dark-gray;
        transition: transform 0.2s;
    }

    input {
        background: transparent;
        border: none;
        color: white;
        font-size: 1.3rem;
        line-height: 1.3;
        outline: none;

        &.Password {
            -webkit-text-security: disc !important;
        }
    }
}