.otnotice-section {
    * {
        font-family: 'beatrice';
        font-weight: lighter;
    }

    h1 {
        font-size: 7rem;
    }

    h2 {
        font-size: 3rem;
    }

    h3 {
        font-size: 2.5rem;
    }

    p {
        font-weight: 600;
    }

    a {
        color: #492728;
        font-weight: 600;

        &:hover {
            color: rgb(152, 69, 69);
        }
    }

    ul {
        li {
            color: black;
            font-weight: 600;
        }
    }
}

.otnotice {
    padding: 10rem;
}