@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Header {
    display: flex;
    font-family: 'newyork';
    justify-content: space-between;
    align-items: center;
    padding: 5px 8rem 5px 3rem;
    min-height: 4.5rem;
    max-height: 6.5rem;

    @include media('<=mobile') {
        padding: 0 4rem 1rem 2rem;
    }

    * {
        font-family: inherit;
    }

    &.PinkBg {
        background: $header-pink;
    }

    &.WhiteBg {
        background: white;
    }

    &.GrapeBg {
        background: $grape;
    }

    & > div {
        display: flex;
        align-items: center;
    }

    .MenuIcon {
        color: black;
        
        &:hover {
            cursor: pointer;
        }

        @include media('>mobile') {
            display: none;
        }
    }

    .LogoMobile {
        display: block;

        @include media('>mobile') {
            display: none;
        }
    }

    .LogoDesktop {

        @include media('<mobile') {
            display: none;
        }
    }

    .Logo {
        max-width: 17rem;
        max-height: inherit;
    }

    .Title {
        color: black;
        font-size: 1.375rem;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        padding: 0.2rem 1.5rem;

        // @include media('<=mobile') {
        //     display: none;
        // }
    }

    .harmonycaLine{
        width: 385px;
    }

    .TitleHarmonyca{
        display: block;
        font-family: 'Saol Display';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        /* identical to box height */

        text-align: center;

        color: #081C48;

        p{
            margin-bottom: -10px;
        }

        sup{
            font-size: 12px;
        }

        @include media('<=mobile') {
            display: none;
        }
    }
}
