@import 'assets/styles/Colors.scss';

.ComparativeTable {
    background: $aubergine;
    border: 1px solid $light-orange;
    color: $light-orange;
    display: inline-block;
    padding: 2.5rem;

    & > table {
        text-align: center;
        border-collapse: collapse;

        & > thead {
            & th {
                color: white;
                padding: 0 1.5rem;
                text-align: center;
                vertical-align: top;
            }

            & p {
                font-size: 1rem;
                font-weight: normal;
                margin-top: 0.5rem;

                &:first-child {
                    font-size: 2rem;
                }
            }
            & sup {
                font-size: 0.8rem;
            }
        } 

        td {
            width: 15rem;
        }

        td:not(.TitleColumn) {
            border-left: 2px solid $light-orange;
            font-size: 1.3rem;
            padding: 2.5rem 0 1.5rem;

            & sup {
                font-size: 0.8rem;
            }
        }
    
        .TitleColumn {
            color: white;
            text-align: start;
            font-size: 1.5rem;
            font-weight: normal;
            padding-right: 1.5rem;

            & sup {
                font-size: 0.6rem;
            }

            & > p{
                padding-left: 3.2rem;
                font-size: 0.8rem;                
            }
        }

        .Hourglass {
            width: 1.75rem;
            height: 2.5rem;
        }

        .Drops {
            margin-top: 0.3rem;
            width: auto;
            height: 1.3rem;
        }

        .Circles{
            width: auto;
            height: 0.85rem;
        }

        .Spring {
            margin-top: 0.3rem;
            width: auto;
            height: 2rem;
        }

        .Face {
            width: auto;
            height: 4.5rem;
        }
    
        .DarkBlueFont,
        .DarkVioletFont,
        .BlueFont,
        .VioletFont,
        .DarkGreenFont {
            font-size: 2.5rem;
            font-weight: normal;
            justify-content: center;
        }
    }
}