@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.LoginWrapper {
    background: $login-pink;
    display: flex;
    height: calc(100% - 6.05rem);
    background-image: url("/assets/images/Nicole-Login.png");
    background-repeat: no-repeat;
background-size: contain;

    * {
        font-family: 'beatrice';
    }

    @include media('<=tablet') {
        height: 100%;
    }

    section {
        &:first-child {
            display: flex;
            flex: 0 1 auto;
 width: 60vw;
            // @include media('<tablet') {
            //    width: 50vw;
            // }

            // @include media('>mobile','<smallDesktop') {
            //     width: 60vw;
            // }

            @include media('<mobile') {
                display: none;
            }
        }

        &:last-child {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            width: 40vw;
            padding: 15px;
            @include media('<=tablet') {
                flex-shrink: 1;
            }

            @include media('<mobile') {
                width: 100vw;
            }

            .CompanyLogo {
                align-self: flex-end;
                height: 4rem;
                margin: 2rem 2rem 0 0;

                @include media('<=smallDesktop') {
                    height: 3rem;
                }

                @include media('<=tablet') {
                    height: 2rem;
                }
            }

            .LoginForm {
                align-items: center;
                display: flex;
                flex: 1 0 auto;
                flex-direction: column;
                margin-top: 1rem;
                width: 40vw;

                @include media('<mobile') {
                    width: 100vw;
                }

                .UserField,
                .PasswordField,
                .ErrorMessage {
                    width: 30rem;

                    @include media('<=tablet') {
                        width: 85%;
                    }
                }

                .UserField {
                    margin-bottom: 1rem;
                }

                .ErrorMessage {
                    background: #FF5757;
                    border: 1px solid red;
                    color: white;
                    font-weight: bold;
                    margin-top: 2rem;
                    padding: 1rem 0.5rem;
                }

                .SubmitButton {
                    background: transparentize(white, 0.3);
                    border: 3px solid #AA98ED;
                    font-size: 1.4rem;
                    height: 3rem;
                    margin: 2rem 0;
                    width: 31rem;

                    &:hover {
                        cursor: pointer;
                    }

                    &:active {
                        background: transparentize(white, 0.4);
                    }

                    @include media('<=tablet') {
                        width: calc(85% + 1rem);
                    }
                }

                .InfoMessage {
                    color: white;
                    font-weight: 500;
                    width: 31rem;
                    

                    @include media('<=tablet') {
                        width: calc(85% + 1rem);
                    }
                }
            }
        }

        .Disclaimer {
            display: flex;
            align-items: flex-end;
            align-self: center;
            justify-content: flex-end;
            text-align: end;
            color: white;
            padding: 0.5rem 0;
            // width: 50rem;
            font-size: 0.6rem;
            width: 40vw;
            @include media('<=tablet') {
                width: calc(100% - 8rem);
            }
        }
    }
}

footer {
    @include media('<=tablet') {
        display: none;
    }

    .FooterRow {
        align-items: center;
        background: #1C1C1C;
        display: flex;
        justify-content: space-between;

        &:first-child {
            padding: 0.7rem 3rem;

            .PrivacyAndTerms {
                display: flex;
                
                .Terms {
                    color: white;
                    text-decoration: underline;
            
                    &:hover {
                        cursor: pointer;
                    }
                }
            }            
        }

        &:last-child {
            padding: 0.3rem 3rem;

            & > img {
                height: 3rem;
            }
        }

        & > img {
            height: 2.5rem;
        }

        & > a {
            align-items: center;
            display: flex;

            & > span {
                color: white;
                font-weight: 500;
                margin-right: 0.7rem;
                text-decoration: underline;
            }
        }

        & p {
            color: white;
            font-weight: 400;
        }
    }
}