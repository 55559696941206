@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Header {
    box-shadow: 0 0.25rem 0.938rem rgba(0, 0, 0, 0.25);
    padding-top: 0.4rem;
    padding-left: 8rem;
    position: relative;
}

main.Content {
    section {
        &:first-child {
            align-items: center;
            background: $header-pink;
            display: flex;
            justify-content: center;

            & > h1 > span {
                color: white;
                display: flex;
                flex-direction: column;
                font-family: 'newyork';
                font-size: 4.6rem;
                font-weight: 400;
                line-height: 1.1;
                margin: 0;
                text-transform: uppercase;
            }
        }

        &:nth-child(2) {
            background: $grape;
            border-bottom: 1px solid $bright-orange;
            padding: 4.5rem 0 1rem 0;

            .ReferenceIcon {
                align-self: flex-start;
                margin-left: 25rem;
                width: 2.5rem;
                
                &:hover {
                    cursor: pointer;
                }

                @include media('<=smallDesktop') {
                    margin-left: 15rem;
                }
            }

            & > h2 {
                color: white;
                font-family: 'newyork';
                font-size: 2.3rem;
                font-weight: 400;
                margin: 1rem 0;
                text-align: center;
                text-transform: uppercase;
            }

            & > .SmallSeparator {
                border-bottom: 2px solid #DA9E76;
                margin: auto;
                width: 6.375rem;
            }

            .Benefits {
                display: flex;
                justify-content: space-between;
                margin: auto;
                padding: 6.5rem 0 7rem;
                max-width: 65rem;

                .BenefitsColumn {
                    display: inline-flex;
                    flex-direction: column;

                    .BenefitInfo {
                        align-items: flex-end;
                        display: flex;

                        &:first-child {
                            margin-bottom: 4rem;
                        }

                        .BenefitIcon {
                            align-items: center;
                            background: #2D1129;
                            border: 1px solid $bright-orange;
                            border-radius: 1.2rem;
                            box-shadow: 0 0.25rem 0.938rem rgba(0, 0, 0, 0.25);
                            display: flex;
                            height: 6rem;
                            justify-content: center;
                            margin-right: 2rem;
                            width: 6.5rem;
                        }

                        .BenefitDescription {
                            color: white;
                            font-size: 1.15rem;
                            font-weight: 400;
                            padding-bottom: 0.5rem;

                            strong {
                                font-size: 0.9rem;
                                font-weight: 700;
                                text-transform: uppercase;

                                &.Capitalized {
                                    display: inline-block;

                                    &:first-letter {
                                        font-size: 1.2rem;
                                    }
                                }
                            }

                            sup {
                                font-size: 0.8rem;
                            }
                        }
                    }
                }
            }
        }

        &:nth-child(3) {
            align-items: center;
            background: $aubergine;
            border-bottom: 1px solid $bright-orange;
            display: flex;
            flex-direction: column;
            padding: 7.625rem 0 2rem;

            .ProductsTable {
                margin-bottom: 5.5rem;
            }

            .BackBtn {
                display: flex;
                justify-content: center;
                margin-top: 1.5rem;
            }
        }
    }

    footer {
        .FooterRow {
            align-items: center;
            display: flex;
            justify-content: space-between;

            &:first-child {
                background: $grape;
                padding: 2.5rem 3rem;

                .PrivacyAndTerms {
                    display: flex;
                    
                    .Terms {
                        color: white;
                        text-decoration: underline;
                
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }
            }

            &:last-child {
                background: #1C1C1C;
                padding: 1.5rem 3rem;

                & > img {
                    height: 1.5rem;
                }
            }

            & > img {
                height: 2.5rem;
            }

            & > a {
                align-items: center;
                display: flex;

                & > span {
                    color: white;
                    font-weight: 500;
                    margin-right: 0.7rem;
                    text-decoration: underline;
                }
            }

            & p {
                color: white;
                font-weight: 400;
            }
        }
    }
}

.ReferencesModal {
    max-width: 60%;

    & > div {
        background: transparentize(black, 0.2);
        border-radius: 1.875rem;
        color: white;
        display: inline-block;
        font-size: 1.2rem;
        padding: 2rem;

        p {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}