@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Menu {
    background: $grape;
    box-shadow: 0 0 0.9rem #111;
    color: white;
    height: calc(100% - 7rem);
    overflow-y: auto;
    position: relative;
    max-width: 13.125rem;
    min-width: 13.125rem;

    @include media('<=tablet') {
         height: calc(100% - 0rem);
    }

    sup {
        font-size: 0.5rem;
    }

    .CloseMenuIconWrapper {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;

        .CloseMenuIcon {
            font-size: 1.6rem;

            &:hover {
                color: transparentize(white, 0.1);
                cursor: pointer;
            }
        }

        @include media('>mobile') {
            display: none;
        }
    }

    .CenteredButton {
        display: block;
        margin: auto;

        & > span {
            font-size: 0.8rem;
            font-weight: normal;
            margin-left: 0.5rem;
        }
    }

    .MenuHeader,
    .MenuFooter {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .VistabelAccess {
        padding: 2rem 0 1rem;
        text-align: center;

        & > p {
            font-weight: bold;
            margin-bottom: 0.5rem;
        }

        .ChangeBtn {
            margin: auto;

            & > img {
                margin: auto;
                width: 80%;
            }
        }

        @include media('>mobile') {
            display: none;
        }
    }

    .JuvedermVycrossLogo {
        margin-top: 0.3rem;
        padding: 0.6rem 0.7rem;
        border-radius: 0 1rem 0.8rem 0;

        .ImgTwoHeader {
            padding: 0 1rem;
            width: 6rem;
        }

        .ImgThreeHeader {
            width: 3.5rem;
            padding-left: 5rem;
        }
    }

    .MenuHeader {
        & > p {
            font-family: 'newyork';
            font-size: 1.25rem;
            font-weight: normal;
            text-align: center;
            border-bottom: 1px solid gray;
            margin: 0 2rem;
            padding-bottom: 0.3rem;
        }

        @include media('<=mobile') {
            padding-top: 2rem;
        }

        .CheckBoxWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem 0;


            .CheckBoxNames,
            .CheckBoxes {
                display: flex;
                text-align: right;
                flex-direction: column;
            }

            .CheckBoxNames {
                padding-right: 1rem;
            }

            & > span {
                color: white;
                margin: 0 1rem;
            }

            .CheckboxRounded {
                width: 1rem;
                height: 1rem;
                background-color: transparent;
                border-radius: 50%;
                vertical-align: middle;
                border: 2px solid #ddd;
                appearance: none;
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
            }

            .CheckboxRounded:checked {
                background-color: white;
            }
            
        }

        .ImgOneHeader {
            padding: 0.2rem 2rem 1rem;
        }
    }

    .MenuBody {
        background: $aubergine;
        padding-bottom: 1.2rem;

        .buttonContainer {
            margin: 2px;
        }

        .Treatments {
            display: flex;
            flex-direction: column;
            font-size: 1rem;
            font-weight: 400;
            justify-content: space-evenly;
            padding: 1rem 0;

            .VioletFont,
            .VioletFontClicked,
            .DarkVioletFont,
            .DarkVioletFontClicked,
            .DarkGreenFont,
            .DarkGreenFontClicked,
            .DarkBlueFont,
            .DarkBlueFontClicked,
            .BlueFont,
            .BlueFontClicked,
            .WhiteFontClicked,
            .WhiteFont {
                padding: 0.7rem 2rem;

                &:hover {
                    cursor: pointer;
                }
            }


            .VioletFont {
                color: #B479AD;
            }

            .VioletFontClicked {
                color: #B479AD;
                background: transparentize(#B479AD, 0.8);
            }

            .DarkVioletFont {
                color: #8C83B9;
            }

            .DarkVioletFontClicked {
                color: #8C83B9;
                background: transparentize(#8C83B9, 0.8);
            }

            .DarkGreenFont {
                color: #686B6B;
            }

            .DarkGreenFontClicked {
                color: #686B6B;
                background: transparentize(#686B6B, 0.8);
            }

            .DarkBlueFont {
                color: #5787A9;
            }

            .DarkBlueFontClicked {
                color: #5787A9;
                background: transparentize(#5787A9, 0.8);
            }


            .BlueFont {
                color: #6AC1DB;
            }

            .BlueFontClicked {
                color: #6AC1DB;
                background: transparentize(#6AC1DB, 0.8);
            }

            .WhiteFont {
                color: #FFFFFF;
            }

            .WhiteFontClicked {
                background: transparentize(white, 0.8);
            }
        }
    }

    .MenuFooter {
        .HylacrossLogo {
            width: 5rem;
            padding-left: 5rem;
        }

        .InfoIcon {
            margin: 2rem 0 0.1rem 0.5rem;

            img {
                height: 1.5rem;
                width: auto;
            }
            
            img:hover {
                cursor: pointer;
            }
        }
    }
}

.ReferencesModal {
    max-width: 60%;

    & > div {
        background: transparentize(black, 0.2);
        border-radius: 1.875rem;
        color: white;
        display: inline-block;
        font-size: 1.2rem;
        padding: 2rem;

        p {
            display: inline-block;
            margin-top: 1rem;
        }
    }
}